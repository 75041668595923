// Menu State
export const SET_MENU_COLLAPSED = 'SET_MENU_COLLAPSED';
export const setMenuCollapsed = (collapsed) => {
  return { type: SET_MENU_COLLAPSED, menuCollapsed: collapsed };
};

export const SET_SUBMENU_COLLAPSED = 'SET_SUBMENU_COLLAPSED';
export const setSubMenuCollapsed = (subCollapsed) => {
  return { type: SET_SUBMENU_COLLAPSED, subMenuCollapsed: subCollapsed };
};

// AdminSearch Term
export const SET_ADMINSEARCH_TERM = 'SET_ADMINSEARCH_TERM';
export const setAdminSearchTerm = (term) => {
  return { type: SET_ADMINSEARCH_TERM, adminSearchTerm: term };
};

// AdminSearch Results
export const SET_ADMINSEARCH_RESULTS = 'SET_ADMINSEARCH_RESULTS';
export const setAdminSearchResults = (results) => {
  return { type: SET_ADMINSEARCH_RESULTS, adminSearchResults: results };
};

// MemebershipSearch Results
export const SET_MEMBERSHIPSEARCH_RESULTS = 'SET_MEMBERSHIPSEARCH_RESULTS';
export const setMembershipSearchResults = (results) => {
  return {
    type: SET_MEMBERSHIPSEARCH_RESULTS,
    membershipSearchResults: results,
  };
};
// MembershipSearch ID
export const SET_MEMBERSHIP_DATA = 'SET_MEMBERSHIP_DATA';
export const setMembershipData = (data) => {
  return {
    type: SET_MEMBERSHIP_DATA,
    membershipData: data,
  };
};
// Set Discount Cards
export const SET_DISCOUNTCARDS = 'SET_DISCOUNTCARDS';
export const setDiscountCards = (cards) => {
  return {
    type: SET_DISCOUNTCARDS,
    discountCards: cards,
  };
};

// Set Membership Types
export const SET_MEMBERSHIP_TYPES = 'SET_MEMBERSHIP_TYPES';
export const setMembershipTypes = (types) => {
  return {
    type: SET_MEMBERSHIP_TYPES,
    membershipTypes: types,
  };
};

// Set Alliances
export const SET_ALLIANCES = 'SET_ALLIANCES';
export const setAlliances = (alliances) => {
  return {
    type: SET_ALLIANCES,
    alliances: alliances,
  };
};

// Set treeDataNewDeal
export const SET_TREEDATA_NEW_DEAL = 'SET_TREEDATA_NEW_DEAL';
export const setTreeDataNewDeal = (treeDataNewDeal) => {
  return {
    type: SET_TREEDATA_NEW_DEAL,
    treeDataNewDeal: treeDataNewDeal,
  };
};

// Set userDEtails
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const setUserDetails = (userDetails) => {
  return {
    type: SET_USER_DETAILS,
    userDetails: userDetails,
  };
};

//Set Password Reset Status Message
export const SET_STATUS_MESSAGE = 'SET_STATUS_MESSAGE';
export const setStatusMessage = (statusMessage) => {
  return {
    type: SET_STATUS_MESSAGE,
    statusMessage: statusMessage,
  };
};
